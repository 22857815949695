import { deleted, get, patch, post } from "apis";
import ProfileRoutes from "./Profile.Routes";


const ProfileServices = {
  getMyProfile: async (param) => {
    const data = await get(ProfileRoutes.getMyProfile, param);
    return data;
  },
  updatePicture: async (obj) => {
    const data = await post(ProfileRoutes.updatePicture, obj);
    return data;
  },
  updateProfile: async (obj) => {
    const data = await patch(ProfileRoutes.updateProfile, obj);
    return data;
  },
  changePassword: async (obj) => {
    const data = await patch(ProfileRoutes.changePassword, obj);
    return data;
  },
  // deleteCategory: async (id) => {
  //   const data = await deleted(CategoriesRoutes.deleteCategory + `/${id}`);
  //   return data;
  // },
  // updatedCategory: async (obj, id) => {
  //   const data = await patch(CategoriesRoutes.updatedCategory + `/${id}`, obj);
  //   return data;
  // },
}

export default ProfileServices