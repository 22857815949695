import React, { Fragment } from 'react';

function Footer() {

  return (
    <Fragment>
    </Fragment>
  );
}

export default Footer;