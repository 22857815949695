import React, { Fragment, Suspense } from 'react';
import { Box } from "@mui/material";
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { CircleLoading } from 'components/loaders/Loaders';
import Colors from 'assets/style/Colors';

function DashboardLayout() {

  return (
    <Fragment>

      <Box sx={{ display: 'flex', }}>

        {/* ========== Sidebar ========== */}
        <Sidebar />

        <Box sx={{ width: 1, }}>

          {/* ========== Header ========== */}
          <Header />

          <Box component="main" sx={{ height: 'calc(100vh - 100px)', position: 'relative', overflow: 'auto', py: 2, px: 3, backgroundColor: Colors.snowDrift, }}>
            <Suspense fallback={<CircleLoading />}>
              <Outlet />
            </Suspense>
          </Box>

        </Box>

      </Box>

    </Fragment>
  )
}

export default DashboardLayout