import React, { Fragment } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Button, CardMedia, Stack, Tooltip, Typography } from '@mui/material'
import { PrimaryButton, TertiaryButton } from 'components/buttons/Buttons'
import Colors from 'assets/style/Colors'
import { baseUrl } from 'config/axios'
import { Link, useNavigate } from 'react-router-dom'

function PrimaryCard({ list }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <Grid container sx={{ py: 2, px: 0, backgroundColor: Colors.white, borderRadius: '12px' }}>
        <Grid item md={3} >
          <CardMedia image={baseUrl + list.screenshot} sx={{
            height: '122px',
          }} />
        </Grid>
        <Grid item md={9} sx={{ px: '8px' }}>
          <Typography variant="subtitle1" sx={{
            mb: 1,
            wordBreak: 'break-all'
          }}>
            {list.name}
          </Typography>
          <Tooltip title={list.description}>
            <Typography variant='body2' sx={{
              mb: 2,
              height: '68.63px',
              wordBreak: 'break-all',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical'
            }}>
              {list.description}
            </Typography>
          </Tooltip>
          <Stack spacing={1} direction="row">
            <TertiaryButton
              title="preview"
              btnColor='1px solid #012c58'
              onClick={() => navigate("/preview", { state: list })}
            />
            <Link to={`/edit-template/${list._id}`} >
              <PrimaryButton
                title="edit templates"
                btnColor={Colors.primary}
              />
            </Link>
          </Stack>
          {/* <Stack spacing={2} direction="row">
            <Button variant="outlined" sx={{
              fontSize: '10px',
              borderColor: '1px solid #012c58'
            }}>
              preview
            </Button>
            <Button sx={{
              fontSize: '10px',
              color: Colors.white,
              backgroundColor: Colors.primary,
              ':hover': {
                color: Colors.white,
                backgroundColor: Colors.primary,
              }
            }}>edit this page</Button>
          </Stack> */}
        </Grid>

      </Grid>
    </Fragment >
  )
}

export default PrimaryCard