import { FormControl, TextField, Typography } from "@mui/material";

function InputField(props) {

  const { variant, label, width, placeholder, defaultValue, value, register, error, rows, multiline, size, type, InputProps, disabled, inputProps, onInput, onBlur, helperText, style } = props

  return (
    <FormControl variant="standard" sx={{ width: width ? width : '100%', my: 1.3, ...style, '.MuiFormHelperText-root': { ml: 0 } }} >
      <TextField
        variant={variant ?? "outlined"}
        value={value}
        size={size}
        type={type}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error && true}
        multiline={multiline}
        rows={rows}
        onBlur={onBlur}
        InputProps={InputProps} // *For Component Value 
        inputProps={inputProps} // *For Input Attributes
        onInput={onInput}
        helperText={helperText}
        {...register}
      />
      {error && (
        <Typography color="error" sx={{ fontSize: 12, textAlign: 'left' }}>{error}</Typography>
      )}
    </FormControl>
  );
}

export default InputField