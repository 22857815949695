import { deleted, get, patch, post } from "apis";
import CategoriesRoutes from "./Categories.Routes";

const CategoriesServices = {
  create: async (obj) => {
    const data = await post(CategoriesRoutes.create, obj);
    return data;
  },
  getCategory: async (param) => {
    const data = await get(CategoriesRoutes.getCategory, param);
    return data;
  },

  deleteCategory: async (id) => {
    const data = await deleted(CategoriesRoutes.deleteCategory + `/${id}`);
    return data;
  },
  updatedCategory: async (obj, id) => {
    const data = await patch(CategoriesRoutes.updatedCategory + `/${id}`, obj);
    return data;
  },
}

export default CategoriesServices