import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import Colors from "assets/style/Colors";

export const PrimaryButton = (props) => {

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: props.disabled ? Colors.flashWhite : props.btnColor,
        color: props.disabled ? Colors.smokyBlack : Colors.white,
        textTransform: 'capitalize',
        boxShadow: 'none',
        minWidth: '120px',
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const SecondaryButton = (props) => {

  const { title } = props

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: Colors.flashWhite,
        color: Colors.smokyBlack,
        minWidth: '100px',
        textTransform: 'capitalize',
        '&:hover': {
          background: Colors.flashWhite,
          color: Colors.smokyBlack,
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        }
      }}
    >
      {title}
    </LoadingButton>
  );
}

export const TertiaryButton = (props) => {
  return (
    <LoadingButton
      variant="text"
      {...props}
      sx={{
        border: props.disabled ? Colors.flashWhite : props.btnColor,
        color: props.btnColor,
        minWidth: '100px',
        textTransform: 'capitalize',
        fontWeight: 700,

        p: 0,
        ':hover': {
          background: props.btnColor + '1A'
        }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const ActionButton = (props) => {
  return (
    <IconButton {...props} sx={{ mx: 0.5, p: 0 }}>
      {props.children}
    </IconButton>
  );
}

export const BackButton = ({ onClick }) => {
  return (
    <IconButton
      aria-label="back"
      onClick={onClick}
      size="small"
      sx={{
        bgcolor: Colors.tertiary,
        color: Colors.white,
        '&:hover': {
          bgcolor: Colors.tertiary,
          color: Colors.white
        }
      }}
    >
      <ArrowBack />
    </IconButton>
  );
}