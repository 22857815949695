import { Computer, PermIdentityOutlined, ShortText } from "@mui/icons-material"
import BallotIcon from '@mui/icons-material/Ballot';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';

const Navigations = [
  {
    label: 'Dashboard',
    icon: <Computer />,
    path: '/'
  },

  {
    label: 'Templates',
    icon: <BallotIcon />,
    children: [
      {
        label: 'List',
        path: '/templates-list'
      },

      {
        label: 'Create',
        path: '/create-template'
      },
    ]
  },

  // {
  //   label: 'Categories',
  //   icon: <CategoryTwoToneIcon />,
  //   children: [
  //     {
  //       label: 'List',
  //       path: '/CategoriesList'
  //     },
  //   ]
  // },
  // {
  //   label: 'Account',
  //   icon: <PermIdentityOutlined />,
  //   children: [
  //     {
  //       label: 'AccountSetting',
  //       path: '/AccountSetting'
  //     },
  //   ]
  // },
  {
    label: 'Categories',
    icon: <CategoryTwoToneIcon />,
    path: '/categories'
  },
  {
    label: 'ShortCodes',
    icon: <ShortText />,
    path: '/short-codes'
  },
  {
    label: 'Account Setting',
    icon: <PermIdentityOutlined />,
    path: '/account-setting'
  },
  // {
  //   label: 'Product Management',
  //   icon: <Storefront />,
  //   children: [
  //     {
  //       label: 'Tags',
  //       icon: <LocalOffer />,
  //       path: '/product-management/tags'
  //     },
  //   ]
  // },
]

export default Navigations