import styled from "@emotion/styled";
import { TextareaAutosize } from "@mui/base";
import { FormControl, Typography } from "@mui/material";

function AutoTextAreaFields(props) {
  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
     padding: 12px;
      resize: vertical ;
      border-radius: 4px ;
      background:none;
      font:inherit;
      font-Weight: 500;
      height: 20px ;
  overflow-y: scroll ;
    &:hover {
      border-color: black;
    }
     &:focus {
      border: 2px solid #012c58;
      }
  `,
  );

  const { variant, label, width, placeholder, defaultValue, value, register, error, minRows, multiline, size, type, InputProps, disabled, inputProps, onInput, onBlur, helperText, style } = props

  return (
    <FormControl variant="standard" sx={{ width: width ? width : '100%', my: 1.3, ...style, '.MuiFormHelperText-root': { ml: 0 } }} >
      <StyledTextarea
        variant={variant ?? "outlined"}
        value={value}
        size={size}
        type={type}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error && true}
        multiline={multiline}
        minRows={minRows}
        onBlur={onBlur}
        InputProps={InputProps} // *For Component Value 
        inputProps={inputProps} // *For Input Attributes
        onInput={onInput}
        helperText={helperText}
        {...register}
      />
      {error && (
        <Typography color="error" sx={{ fontSize: 12, textAlign: 'left' }}>{error}</Typography>
      )}
    </FormControl>
  );
}

export default AutoTextAreaFields