import { get, patch, post } from "apis";
import NdaTemplatesRoutes from "./NdaTemplates.Routes";

const NdaTemplatesServices = {
  create: async (obj) => {
    const data = await post(NdaTemplatesRoutes.create, obj);
    return data;
  },
  getTemplate: async (param) => {
    const data = await get(NdaTemplatesRoutes.getTemplate, param);
    return data;
  },
  getDetails: async (id) => {
    const data = await get(NdaTemplatesRoutes.getDetails + id);
    return data;
  },
  updatedTemplate: async (obj, id) => {
    const data = await patch(NdaTemplatesRoutes.updatedTemplate + `${id}`, obj);
    return data;
  },
}

export default NdaTemplatesServices