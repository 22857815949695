import { Box, Typography, Grid } from '@mui/material'
import Colors from 'assets/style/Colors'
import { BackButton, PrimaryButton } from 'components/buttons/Buttons'
import { baseUrl } from 'config/axios'
import React, { Fragment } from 'react'
import { Link, useLocation, } from 'react-router-dom'

function Preview() {
  const { state } = useLocation()
  return (
    <Fragment>
      <Link to="/templates-list">
        <Box>
          <BackButton />
        </Box>
      </Link>
      <Box sx={{ width: '43%', mb: 3, mx: 'auto', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle1" >
          NDA Details
        </Typography>
        <PrimaryButton
          title="View PDF"
          onClick={() => window.open(`${baseUrl + state?.pdf}`, "_blank")}
          btnColor={Colors.primary}
        />
      </Box>
      <Grid container  >
        <Grid item md={12} sx={{ display: "flex", justifyContent: 'center' }}>
          <Box component="img" src={baseUrl + state.screenshot} sx={{ Object: "fill", height: '600px', }} />
        </Grid>
        {/* <Grid item md={12} >
          <Stack spacing={2} direction="row" sx={{ py: 2, display: "flex", justifyContent: 'center' }}>
            <PrimaryButton
              title="Back"
              btnColor={Colors.black}
            />
            <PrimaryButton
              title="Save"
              btnColor={Colors.secondary}
            />
          </Stack>
        </Grid> */}
      </Grid>

      {/* <Box sx={{
        py: 6, display: 'flex',
        justifyContent: 'center',
      }}>
        <Box sx={{}}>
          <Typography variant="subtitle1" sx={{
            pb: '20px'
          }}>
            NDA Details
          </Typography>
        </Box>

        <Box component="img" src={Images.form} sx={{
          Object: "fill",
          height: '600px',
        }} />
        <Stack spacing={2} direction="row" sx={{
          py: 2
        }}>
          <PrimaryButton
            title="Back"
            btnColor={Colors.black}
            borderRadius={'8px'}
            minWidth={'100px'}
          />
          <PrimaryButton
            title="Save"
            btnColor={Colors.success}
            borderRadius={'8px'}
            minWidth={'100px'}
          />
        </Stack>
      </Box> */}
    </Fragment>
  )
}

export default Preview