import React, { Fragment, Suspense } from 'react';
import { Box } from "@mui/material";
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { CircleLoading } from 'components/loaders/Loaders';

function WebLayout() {
  return (
    <Fragment>

      {/* ========== Header ========== */}
      <Header />

      {/* ========== Main ========== */}
      <Box component="main">
        <Suspense fallback={<CircleLoading />}>
          <Outlet />
        </Suspense>
      </Box>

      {/* ========== Footer ========== */}
      <Footer />

    </Fragment>
  )
}

export default WebLayout