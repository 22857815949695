import { AddBusinessTwoTone, } from '@mui/icons-material';
import { Box, Grid, IconButton, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, styled, tableCellClasses, } from '@mui/material'
import CategoriesServices from 'apis/category/CategoriesServices';
import { DeleteIcon, EditIcon } from 'assets/images/Images';
import Colors from 'assets/style/Colors'
import { PrimaryButton } from 'components/buttons/Buttons';
import SimpleDialog from 'components/dialog/SimpleDialog';
import InputField from 'components/fields/InputField';
import SearchField from 'components/fields/SearchField';
import { CircleLoading } from 'components/loaders/Loaders';
import { ErrorToaster, SuccessToaster } from 'components/toaster/Toaster';
import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
  '&:nth-of-type(odd)': {
    backgroundColor: Colors.desertStorm,
  },

}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
    fontWeight: 700,
    border: 0,
    whiteSpace: 'nowrap',
    backgroundColor: Colors.white,

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
  },
}));


function CategoriesList() {

  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const { setValue: setValue2, register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState('');

  const setDefaultData = (cat) => {
    setValue2("categoryName", cat)
    setEditCategory(true);
  }

  //*addCategory
  const addCategory = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        name: formData.categoryName
      }
      const { message } = await CategoriesServices.create(obj)
      SuccessToaster(message)
      reset()
      setOpen(false)
      getCategory()
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  //*Get Category
  const getCategory = async (search, page, limit) => {
    setLoader(true)
    try {
      const Search = search === undefined ? searchKey : search
      const Page = page ? page : currentPage
      const Limit = limit ? limit : pageLimit
      setSearchKey(Search)
      setCurrentPage(Page)
      setPageLimit(Limit)
      let obj = {
        page: Page,
        limit: Limit,
        search: Search,
      }
      const { data } = await CategoriesServices.getCategory(obj)
      const cat = data.categories.slice(1)
      setCategory(cat)
      setTotalCount(data?.totalCount - 1)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)

    }
  }

  //*Delete Category
  const deleteCategory = async () => {
    setLoading(true)
    try {
      const { message } = await CategoriesServices.deleteCategory(categoryId)
      SuccessToaster(message)
      setCategoryId()
      setDeleteDialog(false)
      getCategory()
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  //*Update Category
  const updateCategory = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        name: formData.categoryName
      }
      const { message } = await CategoriesServices.updatedCategory(obj, categoryId)
      SuccessToaster(message)
      getCategory()
      reset2()
      setEditCategory(false)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCategory()
  }, [])

  return (
    <Box >
      {/* ========== add Dialog ========== */}
      <SimpleDialog
        open={open}
        onClose={() => setOpen(false)}
        title='Add new Category'
      >
        <Box component={'form'} onSubmit={handleSubmit(addCategory)}>
          <InputField
            label='Category Name'
            type="text"
            error={errors?.categoryName?.message}
            register={register("categoryName", {
              required: 'Please enter category.',
            })}
          />
          <PrimaryButton
            title='Save'
            type='submit'
            loading={loading}
            btnColor={Colors.secondary}
          />
        </Box>
      </SimpleDialog>

      {/* ========== Edit Dialog ========== */}
      <SimpleDialog
        open={editCategory}
        onClose={() => { setEditCategory(false) }}
        title='Edit Category'
      >
        <Box component={'form'} onSubmit={handleSubmit2(updateCategory)}>
          <InputField
            label={'Edit Category Name'}
            type="text"
            error={errors2?.categoryName?.message}
            register={register2("categoryName", {
              required: 'Please enter edit category.',
            })}
          />
          <PrimaryButton
            title='Save'
            type='submit'
            loading={loading}
            btnColor={Colors.secondary}
          />
        </Box>
      </SimpleDialog>

      {/* ========== Delete Dialog ========== */}
      <SimpleDialog
        open={deleteDialog} onClose={() => setDeleteDialog(false)} title={'Are you sure to remove this?'} action={deleteCategory} loading={loading}>
        <Stack spacing={2} direction="row" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '16px' }} >
          <PrimaryButton
            title='Yes'
            loading={loading}
            btnColor={Colors.secondary}
            onClick={() => deleteCategory()}
          />
          <PrimaryButton
            title='No'
            onClick={() => setDeleteDialog(false)}
            btnColor={Colors.black}
          />
        </Stack>
      </SimpleDialog>

      <Box sx={{
        bgcolor: [Colors.white],
        p: 2,
        borderRadius: '12px',
        my: 2
      }}>
        <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}> Categories  List</Typography>
      </Box>
      <Grid container alignItems='center' justifyContent="space-between" sx={{
        bgcolor: [Colors.white],
        p: 2,
        borderRadius: '12px',
        my: 2
      }}>
        <Grid item md={3} >
          <Box sx={{ width: '85%' }}>
            <SearchField searchKey={(key) => getCategory(key, 1, '')} />
          </Box>
        </Grid>
        <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-end", }}>
          <PrimaryButton
            title="Add New category"
            startIcon={<AddBusinessTwoTone />}
            btnColor={Colors.secondary}
            onClick={() => setOpen(true)}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: 2, maxHeight: { xs: 'auto', sm: 'auto', md: 'calc(100vh - 300px)' } }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <Cell >Name</Cell>
              <Cell sx={{ width: "20%" }} >Action</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader ? (
              <Fragment>
                {category?.length > 0 ? (
                  <Fragment>
                    {category.map((row, index) => (
                      <Row key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <Cell sx={{ py: '12px', }} >
                          {row.name}
                        </Cell>
                        <Cell sx={{ py: '12px', }}>
                          <Tooltip title="Edit" >
                            <IconButton onClick={() => { setCategoryId(row._id); setDefaultData(row.name) }}>
                              <EditIcon sx={{ fontSize: "6px", color: Colors.secondary }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" >
                            <IconButton onClick={() => { setCategoryId(row._id); setDeleteDialog(true) }} >
                              <DeleteIcon sx={{ fontSize: "20px", color: Colors.danger }} />
                            </IconButton>
                          </Tooltip>
                        </Cell>
                      </Row>
                    ))}
                  </Fragment>
                ) : (
                  <Row>
                    <Cell colSpan={2} align="center" sx={{ fontWeight: 600 }}>
                      No data found
                    </Cell>
                  </Row>
                )}
              </Fragment>
            ) : (
              <Row>
                <Cell colSpan={2} align="center" sx={{ fontWeight: 600 }}>
                  <CircleLoading />
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ bgcolor: [Colors.white], p: 2, borderRadius: '12px', my: 3, display: 'flex', justifyContent: 'end', }}>
        <Pagination
          count={Math.ceil(totalCount / pageLimit)}
          showFirstButton
          showLastButton
          onChange={(e, page) => getCategory(undefined, page, '')}
        />
      </Box>
    </Box >
  )
}

export default CategoriesList