import moment from "moment/moment";

// *Email Regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// *Capitalize
export const Capitalize = (str) => {
  const array = str.split(" ");

  for (var i = 0; i < array.length; i++) {
    array[i] = array[i].charAt(0).toUpperCase() + array[i].slice(1);
  }
  const capitalString = array.join(" ");
  return capitalString
}

// *For Date Format
export const getDateFormate = (date) => {
  if (!date) return
  const newDate = moment(date).format('YYYY-MM-DD')
  if (newDate === 'Invalid date') {
    return ''
  }
  return newDate
}

export const getDate = (date) => {
  if (!date) return
  const newDate = moment(date).format('YYYY-MM-DD')
  if (newDate === 'Invalid date') {
    return ''
  }
  return newDate
}

export const getDateTime = (date) => {
  if (!date) return
  const newDate = moment(date).format('DD-MMM-YYYY  h:mm A')
  return newDate
}